<template>
  <div>
    <H5header :type="2"></H5header>
    <div class="top">
      <div class="titlebox">
        <div class="xian"></div>
        <div class="title">选择报名项目</div>
      </div>
      <div class="but" @click="signup">
        <img src="../../assets/image/viewlist.png" alt="">
        <p>查看我的报名</p>
      </div>
    </div>
    <div class="nian">
      <div style="display: flex;align-items: center;">
        <img src="../../assets/image/project2.png" alt="">
        <p>组别</p>
      </div>
      <div class="select">
        <a-select option-label-prop="label" placeholder="请选择" v-model="info.groupName" allowClear>
          <a-select-option v-for="item in options1" :key="item.itemValue" :value="item.itemValue"
            :label="item.itemText">
            <a-icon type="check" v-if="item.itemValue === info.groupName" />
            {{ item.itemText }}
          </a-select-option>
        </a-select>
      </div>
    </div>
    <div class="xian2"></div>
    <div class="buts" @click="searchfor">
      搜索
    </div>
    <div class="zx-box" v-if="tableData.length === 0">
      <img class="zw" src="../../assets/image/333.png" alt="">
      <div class="zwxx">暂无报名项目</div>
    </div>
    <div v-else style="padding-bottom: 20PX;">
      <div class="box" v-for="(item, index) in tableData" :key="index">
        <div class="tou">
          <div class="title">
            <img src="../../assets/image/project2.png" alt="">
            <span>组别：</span>
            <div @click="getcertificate(item.groupName, '组别')" style="color: #2A7AFF;">{{ item.groupName }}</div>
          </div>
          <div class="title">
            <img src="../../assets/image/group.png" alt="">
            <span>项目：</span>
            <div @click="getcertificate(item.projectName, '项目')" style="color: #2A7AFF;">{{ item.projectName }}</div>
          </div>
        </div>
        <div class="low">
          <div class="title">
            <img src="../../assets/image/gender.png" alt="">
            性别：<span>{{ item.sex == 1 ? '男' : item.sex == 2 ? '女' : '' }}</span>
          </div>
          <div class="title">
            <img src="../../assets/image/dateofbirth.png" alt="">
            出生日期：<span> {{ item.birthDateStart }} ~ {{ item.birthDateEnd }}</span>
          </div>
          <div class="buts" @click="newlyadded(item)">
            报名
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Vue from 'vue'
import Meta from 'vue-meta'
Vue.use(Meta)
import H5header from "./components/header2.vue"
export default {
  components: {
    H5header
  },
  props: {},
  data() {
    return {
      tableData: [],
      info: {
        matchId: this.$route.query.matchId,
        groupName: ''
      },
      options1: []
    };
  },
  computed: {},
  watch: {},
  methods: {
    searchfor() {
      this.projectRegList()
    },
    projectRegList() {
      this.$api.projectRegList(this.info).then(res => {
        this.tableData = res.data.data.result.list
        const result = res.data.data.result.groupNames.map(name => ({ itemText: name, itemValue: name }));
        this.options1 = result
      })
    },
    signup() {
      this.$router.push({
        path: '/h5/registerIndex',
        query: {
          refresh: new Date().getTime(),
          menuIndex: 3,
        },
      })
    },
    newlyadded(row) {
      this.$router.push({
        path: '/h5/registrationform',
        query: {
          refresh: new Date().getTime(),
          matchId: this.$route.query.matchId,
          groupName: row.groupName,
          projectName: row.projectName,
          settingsId: row.settingsId,
          projectId: row.id,
          projectSettingId: row.projectSettingsId,
          groupSettingId: row.groupSettingsId,
        },
      })
    },
    getcertificate(e, title) {
      this.$alert(e, title, {
        confirmButtonText: '关闭',
        callback: action => { }
      });
    }
  },
  created() {
    this.projectRegList()
  },
  mounted() {

  },
  beforeCreate() { },
  beforeMount() { },
  beforeUpdate() { },
  updated() { },
  beforeDestroy() { },
  destroyed() { },
  activated() { },
}
</script>

<style lang='scss' scoped>
.top {
  display: flex;
  justify-content: space-between;
  margin: 20PX 40PX;

  .titlebox {
    display: flex;
    align-items: center;

    .xian {
      width: 5PX;
      height: 15PX;
      background: #3E79CB;
      border-radius: 3PX;
    }

    .title {
      font-family: Source Han Sans CN;
      font-weight: bold;
      font-size: 15PX;
      margin-left: 10PX;
      color: #353333;
      background: linear-gradient(150deg, #2F6BBF 0.146484375%, #7FB5FF 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .but {
    width: 150PX;
    height: 35PX;
    background: #1E71EF;
    border-radius: 50PX;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 15PX;

    img {
      margin-right: 5PX;
      width: 14PX;
      height: 16PX;
    }
  }
}

.zx-box {
  margin-top: 40PX;
  display: flex;
  flex-direction: column;
  align-items: center;

  .zw {
    width: 257PX;
    height: 162PX;
  }

  .zwxx {
    font-family: SourceHanSansCN;
    font-weight: 500;
    font-size: 15PX;
    color: #989898;
    margin-top: 27PX;
    margin-bottom: 40PX;
  }
}

.box {
  margin: 0 20PX;
  width: calc(100% - 40PX);
  height: 160PX;
  background: url('../../assets/image/777.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin-bottom: 20PX;

  .tou {
    display: flex;
    align-items: center;
    padding-top: 18PX;

    .title {
      color: #629AE4;
      display: flex;
      align-items: center;
      font-size: 13PX;
      width: 40%;
      flex-shrink: 0;

      img {
        width: 16PX;
        height: 16PX;
        margin-right: 5PX;
      }

      span {
        flex-shrink: 0;
      }

      div {
        color: #000;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .title:nth-child(1) {
      margin-left: 20PX;
    }

    .title:nth-child(2) {
      margin-left: 10PX;
    }
  }
}

.low {
  padding-top: 30PX;
  margin-left: 20PX;

  .title {
    color: #629AE4;
    display: flex;
    align-items: center;
    font-size: 13PX;

    img {
      width: 16PX;
      height: 16PX;
      margin-right: 5PX;
    }

    span {
      color: #000;
    }
  }

  .title:nth-child(2) {
    margin-top: 10PX;
  }

  .buts {
    width: calc(100% - 40PX);
    height: 30PX;
    background: linear-gradient(0deg, #1F6EEE, #60B8FF);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30PX;
    margin-top: 10PX;
  }
}

.nian {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 20PX;

  img {
    width: 17PX;
    height: 17PX;
    flex-shrink: 0;
  }

  p {
    font-size: 14PX;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    margin-left: 12PX;
    flex-shrink: 0;
  }

  .select ::v-deep .el-select .el-input .el-input__inner {
    border-color: transparent !important;
    background-color: transparent !important;
    box-shadow: none !important;
    color: #000;
  }

  .select ::v-deep .el-select .el-input,
  .select ::v-deep .el-select-dropdown .el-select-dropdown__item.selected {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  .select ::v-deep .el-select .el-input .el-select__caret.is-reverse {
    transform: rotateZ();
  }

  .select ::v-deep .el-select .el-input .el-select__caret {
    color: #C0C4CC;
    font-size: 14PX;
    transition: transform .3s;
    transform: rotateZ(89deg);
    cursor: pointer;
    left: 12PX;
    position: relative;
  }

  .select ::v-deep input::-webkit-input-placeholder {
    color: #000;
  }

  .select ::v-deep .el-select .el-input .el-select__caret {
    color: #000;
  }

  ::v-deep .el-input .el-input__inner {
    border: none !important;
    padding: 0 12PX;
    background: none;
  }

  ::v-deep input::-webkit-input-placeholder {
    color: #000;
  }
}

.xian2 {
  margin: 0 15PX;
  height: 1PX;
  background: rgba(194, 194, 194, 0.2);
}

.buts {
  margin: 20PX 20PX;
  width: calc(100% - 40PX);
  height: 37PX;
  background: linear-gradient(to bottom, #60B8FF, #1F6EEE);
  border-radius: 50PX;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16PX;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #FFFFFF;
}
</style>